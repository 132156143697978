/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* navigation-large.less 2013-1-16 *******/
.banner__wrapper-fixed {
  display: block;
  height: 0;
}
.desk {
  border: 0 !important;
  margin-top: 0 !important;
}
.cb-toggle-target-active .banner__wrapper-fixed,
.cb-toggle-target-active .navigation__wrapper-fixed {
  position: static;
  box-shadow: none;
  background: none;
  width: auto;
  max-width: none;
}
.togglenavigation--close,
.togglenavigation,
.navigation .cb-toggle {
  display: none;
}
.menu {
  display: block;
  color: #fff;
}
.menu.path,
.menu:hover,
.menu:focus {
  color: #000;
}
div.sub1,
.sub1 > .item {
  width: auto;
}
div.sub1 {
  display: block;
  margin-left: -40px;
}
.sub1 > .item {
  position: relative;
  z-index: 1;
}
.sub1 > .item:hover:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: '';
  width: 0;
  height: 0;
  margin-left: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
}
.sub1 > .item-empty:hover:before {
  display: none;
}
div.sub1 .menu {
  padding: 0.4em 40px;
}
.item > .sub2 {
  display: none;
}
.item > .sub2 {
  position: absolute;
  top: 100%;
  left: 24px;
  width: 168px;
  background: #fff;
}
.item:hover > .sub2 {
  display: block;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), 2px 4px 4px rgba(0, 0, 0, 0.2);
}
.item-empty > .hover {
  display: none !important;
}
.item > .sub2 .menu {
  padding: 0.2em 5px 0.3em 16px;
}
.item > .sub2 > .init > .menu {
  padding-top: 0.9em;
}
.item > .sub2 > .exit > .menu {
  padding-bottom: 0.9em;
}
.desk > .sub2 {
  display: block;
  margin-top: 110px;
}
.desk > .sub2 .menu {
  text-indent: -15px;
  padding-left: 15px;
}
#view .navigation .sub3 {
  display: none;
}
#edit .navigation .sub3,
.desk > .sub2 .sub3 {
  display: block;
}
div.sub3 .menu {
  padding-left: 2em !important;
}
#services {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-size: 1.2rem;
}
#services > .meta {
  display: inline;
  margin-right: 0.6em;
}
#services .meta {
  float: right;
}
#cmsbox {
  position: absolute;
  right: 0;
  bottom: 0;
}
/******* layout-medium.less 2013-1-16 *******/
.cb-layout2 #head,
.cb-layout3 #head {
  float: right;
  margin-top: 49px;
  width: 74.32432432%;
}
.home {
  clear: left;
  margin-top: 27px;
}
.desk {
  width: 92.5% !important;
}
.ritschi-palutschi-kommt-aus-baar {
  float: left;
  width: 288px;
  margin-right: 16px;
}
.footer h3 {
  margin: 0;
}
.desk > .sub2 {
  width: 22.97297297%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.75438596%;
  margin-left: 1.75438596%;
}
.area .part,
.area > .grid table {
  margin-right: 1.75438596%;
  margin-left: 1.75438596%;
  width: 96.49122807%;
}
.area .tiny {
  width: 46.49122807%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96.49122807%;
}
.area > .slim .tiny {
  width: 46.49122807%;
}
.cb-layout1 .area {
  width: 100%;
}
.cb-layout1 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area h2,
.cb-layout1 .area .foot {
  margin-right: 1.31578947%;
  margin-left: 1.31578947%;
}
.cb-layout1 .area .part,
.cb-layout1 .area > .grid table {
  margin-right: 1.31578947%;
  margin-left: 1.31578947%;
  width: 97.36842105%;
}
.cb-layout1 .area .tiny {
  width: 47.36842105%;
}
.cb-layout1 .area > .slim {
  width: 50%;
}
.cb-layout1 .area > .slim h2,
.cb-layout1 .area > .slim .foot,
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  margin-right: 2.63157895%;
  margin-left: 2.63157895%;
}
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  width: 94.73684211%;
}
.cb-layout1 .area > .slim .tiny {
  width: 94.73684211%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  float: right;
  margin-right: -1.35135135%;
  width: 77.02702703%;
}
.cb-layout1 .main {
  margin-left: -1.35135135%;
  width: 102.7027027%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #0080c5;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #0080c5;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #005f92;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.13333333em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #006fac #004e79 #004e79 #006fac;
  border-radius: 2px;
  background-color: #0080c5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  line-height: 2.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #006fac #004e79 #004e79 #006fac;
  background-color: #008ad4;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #006fac #004e79 #004e79 #006fac;
  background-color: #005f92;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/*# sourceMappingURL=./screen-medium.css.map */